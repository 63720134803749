import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons"

const ContactUs = (props) => {
  return (
    <div>
      <div className="bg-dark-green px-10 sm:px-20 md:px-40 pt-20 pb-4 text-left">
        <h1 className="text-gold text-4xl monomaniac-one mb-5">Contact Us</h1>
      </div>

      <div className="flex flex-col gap-10 bg-white px-10 sm:px-20 md:px-40 pt-4 pb-20 text-left">
        <div>
          <h1 className="text-gold text-4xl monomaniac-one mb-5">Reach out to us!</h1>
          <p className="text-sm jet-brains-mono">
            If you have any questions, comments, or concerns about our organization, please feel free to
            reach out through the form below and we will do our best to respond as quickly as possible. We
            also welcome you to use any of our social media, phone numbers, or email to reach out about any
            inquiries. Thank you.
          </p>
        </div>

        <form action="">
          <div className="flex flex-col md:flex-row items-stretch gap-4 md:gap-20">
            <div className="flex flex-col w-full md:w-1/3 gap-4">
              <div className="flex flex-col">
                <label htmlFor="name" className="text-lg text-gold mb-1 monomaniac-one">Name (Required)</label>
                <input type="text" id="name" name="name" className="bg-fog/[0.35] p-1 text-sm rounded" />
              </div>
              <div className="flex flex-col">
                <label htmlFor="email" className="text-lg text-gold mb-1 monomaniac-one">Email (Required)</label>
                <input type="text" id="email" name="email" className="bg-fog/[0.35] p-1 text-sm rounded" />
              </div>
              <div className="flex flex-col">
                <label htmlFor="subject" className="text-lg text-gold mb-1 monomaniac-one">Subject</label>
                <input type="text" id="subject" name="subject" className="bg-fog/[0.35] p-1 text-sm rounded" />
              </div>
            </div>

            <div className="flex flex-col w-full md:w-2/3 h-[212px]">
              <label htmlFor="message" className="text-lg text-gold mb-1 monomaniac-one">Your Message</label>
              <textarea id="message" name="message" className="bg-fog/[0.35] p-1 resize-none text-sm rounded h-full" />
            </div>
          </div>
        </form>

        <div className="flex flex-col sm:flex-row gap-10 md:gap-20">
          <div className="flex flex-col w-full md:w-1/3">
            <div className="text-lg text-gold mb-2 monomaniac-one">Social Media</div>
            <div className="inline-flex gap-6 text-5xl text-black align-middle">
              <FontAwesomeIcon icon={faFacebook} />
              <FontAwesomeIcon icon={faInstagram} />
              <FontAwesomeIcon icon={faLinkedin} />
            </div>
          </div>
          <div className="flex flex-col gap-2 w-full md:w-2/3">
            <div className="text-lg text-gold monomaniac-one">Email</div>
            <div className="text-sm jet-brains-mono">Board Email: <a href="mailto:board@alhaqqfoundation.ca" className="text-gold underline underline-offset-4">board@alhaqqfoundation.ca</a></div>
            <div className="text-sm jet-brains-mono">General Inquiries: <a href="mailto:board@alhaqqfoundation.ca" className="text-gold underline underline-offset-4">admin@alhaqqfoundation.ca</a></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs