import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "reactn"
import Layout from "./Components/Layout"
import HomePage from "./Components/Home";
// import AboutUs from "./Components/AboutUs"
import News from "./Components/News"
// import GetInvolved from "./Components/GetInvolved"
import ContactUs from "./Components/ContactUs"
import Donate from "./Components/Donate";
import UnderConstruction from "./Components/UnderConstruction"
import PageNotFound from "./Components/PageNotFound"

import Overview from "./Components/AboutUs/Overview"
import OurPeople from "./Components/AboutUs/OurPeople"
import Programs from "./Components/AboutUs/Programs"
import ImpactsAndReports from "./Components/AboutUs/ImpactsAndReports"

import Volunteering from "./Components/GetInvolved/Volunteering"
import SponsorshipsAndCollaboration from "./Components/GetInvolved/SponsorshipsAndCollaboration"

import './App.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<HomePage />} />
            <Route path="about-us" element={<UnderConstruction />} />
            <Route path="news" element={<News />} />
            <Route path="get-involved" element={<UnderConstruction />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="donate" element={<Donate />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
          <Route path="/about-us/" element={<Layout />}>
            <Route path="overview" element={<Overview />} />
            <Route path="our-people" element={<OurPeople />} />
            <Route path="programs" element={<Programs />} />
            <Route path="impacts-and-reports" element={<ImpactsAndReports />} />
          </Route>
          <Route path="/get-involved/" element={<Layout />}>
            <Route path="volunteering" element={<Volunteering />} />
            <Route path="sponsorships-and-collaboration" element={<SponsorshipsAndCollaboration />} />
          </Route>
          <Route path="/admin/" element={<Layout />}>
            <Route path="login" element={<UnderConstruction />} />
            <Route path="dashboard" element={<UnderConstruction />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
