
const Donate = (props) => {
  return (
    <div>
      {/* -------------------- Block 1 -------------------- */}
      <div className="px-20 md:px-40 py-20 text-left">
        <h1 className="text-black text-4xl monomaniac-one mb-5">Donate</h1>

        <div className="flex flex-row xl:flex-col justify-evenly flex-wrap gap-8">
          Donate content
        </div>
      </div>
    </div>
  )
}

export default Donate