import program1 from "../../assets/program1.jpg"
import program2 from "../../assets/program2.webp"


const Card = (props) => {
  return (
    <button
      className="flex flex-col w-[250px] xl:w-full xl:flex-row text-left shadow shadow-[4px_4px_10px_0px_rgba(0,0,0,0.3)] rounded-lg xl:mb-7
      hover:shadow-[2px_2px_5px_0px_rgba(0,0,0,0.3)] transition-all ease-in-out duration-300
      active:shadow-[0px_0px_1px_0px_rgba(0,0,0,0.3)] active:transition-none"
    >
      <div
        className="relative rounded-lg overflow-hidden w-full xl:w-[600px] h-[125px] xl:h-[200px]"
        style={{
          backgroundImage: `url(${props.image})`,
          backgroundPosition: "center",
          backgroundSize: "110% auto"
        }}
      ></div>
      <div className="w-full px-4 xl:px-8 py-2">
        <h1 className="text-gold text-2xl xl:text-4xl monomaniac-one mb-2">{props.title}</h1>
        <p className="text-black text-sm jet-brains-mono pb-2">{props.description}</p>
      </div>
    </button>
  )
}

const Programs = (props) => {
  return (
    <div>
      {/* -------------------- Block 1 -------------------- */}
      <div className="px-20 md:px-40 py-20 text-left">
        <h1 className="text-black text-4xl monomaniac-one mb-5">Programs</h1>

        <div className="flex flex-row xl:flex-col justify-evenly flex-wrap gap-8">
          <Card
            image={program1}
            title={'Islamic Development Classes'}
            description={'Learn more about Islamic principles'}
          />
          <Card
            image={program2}
            title={'Travelling Food Bank'}
            description={'Access food anywhere in London'}
          />
          <Card
            image={program2}
            title={'Travelling Food Bank'}
            description={'Access food anywhere in London'}
          />
        </div>
      </div>
    </div>
  )
}

export default Programs