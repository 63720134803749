
const OurPeople = (props) => {
  return (
    <div>
      {/* -------------------- Block 1 -------------------- */}
      <div className="bg-dark-green px-20 md:px-40 py-20 text-left">
        <h1 className="text-gold text-4xl monomaniac-one mb-5">Our People</h1>
        <div>
          <ul className="marker:text-gold list-disc border border-white border-l border-r-0 border-y-0 pl-[10.5px]">
            <li className="pl-4">
              <div className="inline-flex flex-col w-full">
                <div className="text-gold text-xl monomaniac-one mb-1">Our Staff</div>
                <div className="text-white text-sm mb-10 jet-brains-mono">
                  To contact our staff, please email <a href="mailto:admin@alhaqqfoundation.ca" className="text-gold underline underline-offset-4 decoration-1">
                    admin@alhaqqfoundation.ca
                  </a>
                </div>
                <div className="grid grid-cols-2 sm:grid-cols-3">
                  <div className="monomaniac-one mb-10">
                    <h1 className="text-gold text-md">XXXXX</h1>
                    <p className="text-white text-sm">XXXXX</p>
                  </div>
                  <div className="monomaniac-one mb-10">
                    <h1 className="text-gold text-md">XXXXX</h1>
                    <p className="text-white text-sm">XXXXX</p>
                  </div>
                  <div className="monomaniac-one mb-10">
                    <h1 className="text-gold text-md">XXXXX</h1>
                    <p className="text-white text-sm">XXXXX</p>
                  </div>
                  <div className="monomaniac-one mb-10">
                    <h1 className="text-gold text-md">XXXXX</h1>
                    <p className="text-white text-sm">XXXXX</p>
                  </div>
                  <div className="monomaniac-one mb-10">
                    <h1 className="text-gold text-md">XXXXX</h1>
                    <p className="text-white text-sm">XXXXX</p>
                  </div>
                  <div className="monomaniac-one mb-10">
                    <h1 className="text-gold text-md">XXXXX</h1>
                    <p className="text-white text-sm">XXXXX</p>
                  </div>
                </div>
              </div>
            </li>
            <li className="pl-4">
              <div className="inline-flex flex-col w-full">
                <div className="text-gold text-xl monomaniac-one mb-1">Our Board of Directors</div>
                <div className="text-white text-sm mb-10 jet-brains-mono">
                  To contact the board, please email <a href="mailto:board@alhaqqfoundation.ca" className="text-gold underline underline-offset-4 decoration-1">
                    board@alhaqqfoundation.ca
                  </a>
                </div>
                <div className="grid grid-cols-2 sm:grid-cols-3">
                  <div className="monomaniac-one mb-10">
                    <h1 className="text-gold text-md">Ariyan Haq</h1>
                    <p className="text-white text-sm">Founder and Board Chair</p>
                  </div>
                  <div className="monomaniac-one mb-10">
                    <h1 className="text-gold text-md">Mohammed Garrah</h1>
                    <p className="text-white text-sm">Board Member</p>
                  </div>
                  <div className="monomaniac-one mb-10">
                    <h1 className="text-gold text-md">Mahmoud Abdul-Aziz</h1>
                    <p className="text-white text-sm">Board Member</p>
                  </div>
                  <div className="monomaniac-one mb-10">
                    <h1 className="text-gold text-md">Andrew McDonald</h1>
                    <p className="text-white text-sm">Board Member</p>
                  </div>
                  <div className="monomaniac-one mb-10">
                    <h1 className="text-gold text-md">Ehab Rajeh</h1>
                    <p className="text-white text-sm">Board Member</p>
                  </div>
                  <div className="monomaniac-one mb-10">
                    <h1 className="text-gold text-md">Mahmoud Abdul-Aziz</h1>
                    <p className="text-white text-sm">XXXXX</p>
                  </div>
                </div>
              </div>
            </li>
            <li className="pl-4">
              <div className="inline-flex flex-col">
                <div className="text-gold text-xl monomaniac-one mb-1">About Our Board</div>
                <div className="text-white text-sm mb-10 jet-brains-mono">
                  Text <br />
                  Text <br />
                  Text <br />
                  Text
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      {/* -------------------- Block 2 -------------------- */}
      <div className="flex flex-col items-center bg-gold px-20 md:px-40 py-4">
        <h1 className="text-black text-4xl monomaniac-one mb-5">
          Get Involved With Us!
        </h1>
        <div className="text-black flex w-full justify-evenly text-sm jet-brains-mono font-medium  mb-5">
          Our organization only runs on volunteers meaning it is very important for us to find people to help.
          If what we do speaks to you, submit an application and we will do our best to get you involved! Thank you.
        </div>
        <button
          type="button"
          className="bg-dark-green hover:bg-green text-2xl rounded-md px-10 pt-1 pb-2 text-gold shadow-md shadow-black/100 hover:shadow-sm hover:shadow-black/100 active:shadow active:shadow-transparent"
          style={{
            fontFamily: 'Monomaniac One'
          }}
        >
          Apply
        </button>
      </div>
    </div>
  )
}

export default OurPeople