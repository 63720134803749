import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload } from "@fortawesome/free-solid-svg-icons"

const Report = (props) => {
  return (
    <div className="flex flex-col border border-x-0 border-white border-t-0 border-b-1 py-2 mb-4">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-col sm:flex-row sm:gap-8 sm:items-center">
          <div className="text-gold text-xl monomaniac-one">2024 - 2025</div>
          <div className="text-white text-lg jet-brains-mono">Annual Report</div>
        </div>
        <button className="text-gold text-lg">
          <FontAwesomeIcon icon={faDownload} />
        </button>
      </div>
    </div>
  )
}

const ImpactsAndReports = (props) => {
  return (
    <div className="px-10 sm:px-20 md:px-40 pt-20">
      <div className="bg-dark-green px-10 py-5 text-left rounded-tl-2xl rounded-tr-2xl">
        <div className="mb-10">
          <h1 className="flex justify-between items-end gap-5 text-gold text-2xl sm:text-4xl mb-2 monomaniac-one">
            <div className="whitespace-nowrap">Impacts and Reports</div>
            <div className="bg-gold h-[3px] w-full mb-1"></div>
          </h1>
          <p className="text-white text-sm jet-brains-mono">This section contains all of Al-Haqq Foundation’s audited financial reports, annual reports, other public releases</p>
        </div>
        <Report/>
        <Report/>
        <Report/>
        <Report/>
        <Report/>
        <Report/>
      </div>

    </div>
  )
}

export default ImpactsAndReports