import { useRef, useState, useGlobal } from "reactn"
import { Link } from "react-router-dom"
import { logo } from "../assets/assets"
import "./styles/navbar.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleXmark, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons"
import { useScreenWidth, useScreenHeight, screenSizes } from "../utils/windowSize"
import { useEffect } from "react"

const Underline = (props) => {
  return (
    <div
      style={{
        margin: 'auto',
        borderBottom: '2px gold solid',
        borderRadius: '0px'
      }}
      className="hidden md:block transition-all ease-in-out duration-300 w-5 group-hover:w-full"
    >
    </div>
  )
}

const NavBarItem = (props) => {
  return (
    <li className="flex justify-between items-center group">
      <Link
        onClick={props.resetNav}
        to={props.link}
        className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:p-0 dark:text-white md:dark:hover:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
        style={{
          fontFamily: 'Monomaniac One'
        }}
      >
        {props.name}
        <Underline />
      </Link>
    </li>
  )
}

const NavBarDropdown = (props) => {
  return (
    <li
      className="flex flex-col group"
    >
      <button
        className="flex justify-between items-center"
        onClick={() => {
          if (screenSizes.md <= props.screenWidth) {
            props.setMegaDropdown(true);
            props.setMegaDropdownInfo({
              name: props.name,
              links: props.links
            })
          } else {
            props.setActiveDropdown(props.activeDropdown !== props.name ? props.name : '');
          }
        }}
      >
        <div
          className="block text-left py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:p-0 dark:text-white md:dark:hover:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
          style={{
            fontFamily: 'Monomaniac One'
          }}
        >
          {props.name}
          <Underline />
        </div>
        {(props.screenWidth < screenSizes.md) &&
          <FontAwesomeIcon className="mr-4" icon={props.activeDropdown === props.name ? faMinus : faPlus}></FontAwesomeIcon>
        }
      </button>
      {props.activeDropdown === props.name && (props.screenWidth < screenSizes.md) && (
        <div
          className="flex flex-col text-left pl-6 text-light-gray mb-2"
          style={{
            fontFamily: 'Monomaniac One'
          }}
        >
          {props.links.map((link, i) => {
            return (
              <Link
                key={i}
                onClick={props.resetNav}
                to={link.link}
              >
                {link.name}
              </Link>
            )
          })}
        </div>
      )}
    </li>
  )
}

const Navbar = (props) => {
  const navbar = useRef()
  const navbarDefault = useRef()
  const [closed, setClosed] = useState(false)

  // mobile view dropdown
  const [activeDropdown, setActiveDropdown] = useState('')

  // large screen dropdown
  const [megaDropdown, setMegaDropdown] = useState(false)
  const [megaDropdownInfo, setMegaDropdownInfo] = useState({})

  const screenWidth = useScreenWidth()
  const screenHeight = useScreenHeight()

  const resetNav = () => {
    setClosed(false);
    setMegaDropdown(false);
    setMegaDropdownInfo(false);
  }

  const [global, setGlobal] = useGlobal()

  useEffect(() => {
    if (navbar.current) {
      setGlobal({ navHeight: navbar.current.clientHeight })
    }
  }, [navbar, setGlobal])

  useEffect(() => {
    if (megaDropdown) {
      window.scrollTo(0, 0);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [megaDropdown])

  return (
    <nav className="bg-white border-gray-200 dark:bg-gray-900">
      <div className="max-w-screen-xl flex flex-wrap items-center content-center justify-between mx-auto p-4" ref={navbar}>
        <Link onClick={resetNav} to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
          <img src={logo} className="h-16" alt="Al-Haqq Logo" />
          <span
            className="self-center text-left text-2xl whitespace-pre dark:text-white leading-none"
            style={{
              fontFamily: 'Monomaniac One',
              fontSize: '18px',
            }}
          >
            Al-Haqq<br />Foundation
          </span>
        </Link>
        <div className="flex md:order-2 gap-4 md:gap-8 flex-row md:flex-row-reverse items-center md:space-x-0">
          <Link
            to="/donate"
            type="button"
            className="bg-dark-green hover:bg-green rounded-md px-5 pt-1 pb-2 text-gold shadow-md shadow-black/100 hover:shadow-sm hover:shadow-black/100 active:shadow active:shadow-transparent"
            style={{
              fontFamily: 'Monomaniac One'
            }}
          >
            Donate
          </Link>
          <button
            data-collapse-toggle="navbar-default"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-default"
            aria-expanded="false"
            onClick={() => {
              if (navbarDefault.current) {
                if (closed) {
                  navbarDefault.current.classList.add("hidden")
                  setClosed(!closed)
                } else {
                  navbarDefault.current.classList.remove("hidden")
                  setClosed(!closed)
                }
              }
            }}
          >
            <span className="sr-only">Open main menu</span>
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
            </svg>
          </button>
        </div>
        <div className="hidden md:order-1 ml-auto md:mr-8 w-full md:block md:w-auto" id="navbar-default" ref={navbarDefault}>
          <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            <NavBarDropdown
              name={"About Us"}
              screenWidth={screenWidth}
              links={[
                { name: "Overview", link: "/about-us/overview" },
                { name: "Our\xa0People", link: "/about-us/our-people" },
                { name: "Programs", link: "/about-us/programs" },
                { name: "Impacts\xa0and\xa0Reports", link: "/about-us/impacts-and-reports" }
              ]}
              activeDropdown={activeDropdown}
              setActiveDropdown={setActiveDropdown}
              resetNav={resetNav}
              megaDropdown={megaDropdown}
              setMegaDropdown={setMegaDropdown}
              setMegaDropdownInfo={setMegaDropdownInfo}
            />
            <NavBarItem
              name={"News"}
              link={"/news"}
              resetNav={resetNav}
            />
            <NavBarDropdown
              name={"Get Involved"}
              screenWidth={screenWidth}
              links={[
                { name: "Volunteering", link: "/get-involved/volunteering" },
                { name: "Sponsorships\xa0& Collaboration", link: "/get-involved/sponsorships-and-collaboration" },
              ]}
              activeDropdown={activeDropdown}
              setActiveDropdown={setActiveDropdown}
              resetNav={resetNav}
              megaDropdown={megaDropdown}
              setMegaDropdown={setMegaDropdown}
              setMegaDropdownInfo={setMegaDropdownInfo}
            />
            <NavBarItem
              name={"Contact Us"}
              link={"/contact-us"}
              resetNav={resetNav}
            />
          </ul>
        </div>
      </div>
      {megaDropdown && (
        <div
          style={{
            height: `${screenHeight - global.navbarHeight}px`
          }}
          className="relative"
        >
          <div
            className="block absolute bg-light-green w-[430px] h-[280px]"
            style={{
              borderRadius: '50%',
              bottom: '-140px',
              left: '550px',
            }}
          >
          </div>
          <div
            className="block absolute bg-dark-green w-[700px] h-[380px]"
            style={{
              borderRadius: '50%',
              bottom: '-140px',
              left: '-40px',
            }}
          >
          </div>
          <div className="flex justify-end px-10 pt-10">
            <button>
              <FontAwesomeIcon
                onClick={() => {
                  setMegaDropdown(false)
                }}
                className="text-6xl text-right transition-all ease-in-out duration-300 hover:text-gold"
                icon={faCircleXmark}
              />
            </button>
          </div>
          <div className="flex flex-col mx-auto md:flex-row justify-between pl-20 py-10 md:pl-40">
            <div className="text-left w-1/2 mb-5 md:mg-0">
              <h1
                style={{
                  fontFamily: 'Monomaniac One',
                  fontSize: '40px'
                }}
              >
                {megaDropdownInfo.name}
              </h1>
            </div>
            <div className="text-left w-1/2 md:pt-[14px]">
              {megaDropdownInfo.links.map((link, i) => {
                return (
                  <button key={i} className="flex mb-4 block transition-all ease-in-out duration-300 hover:text-gold">
                    <Link
                      onClick={resetNav}
                      to={link.link}
                      style={{
                        fontFamily: 'Jetbrains Mono',
                        fontSize: '24px'
                      }}
                      className="text-left"
                    >
                      <div className="navbar-dropdown-link w-min lg:w-auto">
                        {link.name}
                      </div>
                    </Link>
                  </button>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </nav>
  )
}

export default Navbar