import { useScreenHeight } from "../../utils/windowSize"
import { useGlobal } from "reactn"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-regular-svg-icons"
import { faFacebook, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons"

const DownArrow = (props) => {
  return (
    <div className="relative">
      <div
        className="flex content-center justify-center flex-end items-center"
        style={{
          width: `${props.tailLength}px`,
        }}
      >
        <div
          className="bg-gold rounded"
          style={{
            width: `${props.thickness}px`,
            height: `${props.height}px`,
          }}
        ></div>
      </div>
      <div
        className={`border border-gold`}
        style={{
          position: "absolute",
          bottom: "0px",
          width: `${props.tailLength}px`,
          height: `${props.tailLength}px`,
          borderWidth: `0px ${props.thickness}px ${props.thickness}px 0px`,
          borderRadius: `${props.thickness}px`,
          transform: "rotate(45deg)",
        }}>
      </div>
    </div>
  )
}

const Overview = (props) => {
  const [global] = useGlobal()
  const screenHeight = useScreenHeight()
  return (
    <div>
      {/* -------------------- Block 1 -------------------- */}
      <div
        className="relative overflow-hidden bg-dark-green px-20 md:px-40 py-20"
        style={{ height: screenHeight - global.navbarHeight }}>
        <div
          className="block absolute bg-gold w-[340px] h-[170px] opacity-80"
          style={{
            borderRadius: '50%',
            bottom: '-95px',
            right: '600px',
          }}
        >
        </div>
        <div
          className="block absolute bg-gold w-[430px] h-[280px] opacity-90"
          style={{
            borderRadius: '50%',
            bottom: '-140px',
            right: '350px',
          }}
        >
        </div>
        <div
          className="block absolute bg-gold w-[700px] h-[380px]"
          style={{
            borderRadius: '50%',
            bottom: '-140px',
            right: '-240px',
          }}
        >
        </div>
        <div className="text-left w-1/2">
          <h1 className="text-gold text-4xl lg:text-5xl monomaniac-one mb-5">
            About Us
          </h1>
          <p className="text-white text-xl lg:text-2xl jet-brains-mono font-medium mb-20">
            Bridging communities with compassion and Islamic education
          </p>
          <DownArrow
            height={50}
            thickness={2}
            tailLength={10}
            color={'gold'}
          />
        </div>
      </div>

      {/* -------------------- Block 2 -------------------- */}
      <div className="bg-white px-20 md:px-40 py-20">
        <div className="text-left mb-20">
          <h1 className="text-gold text-4xl monomaniac-one mb-5">
            About Us
          </h1>
          <p className="text-black text-lg lg:text-lg jet-brains-mono font-medium">
            Bridging communities with compassion and Islamic enlightenment
          </p>
        </div>

        <div className="flex flex-col sm:flex-row sm:gap-12">
          <div className="text-left">
            <h1 className="text-gold text-4xl monomaniac-one mb-5">
              Our Mission
            </h1>
            <p className="text-black text-lg lg:text-lg jet-brains-mono font-medium">
              Bridging communities with compassion and Islamic enlightenment
            </p>
          </div>
          <div className="text-left">
            <h1 className="text-gold text-4xl monomaniac-one mb-5">
              Our Values
            </h1>
            <p className="text-black text-lg lg:text-lg jet-brains-mono font-medium">
              Bridging communities with compassion and Islamic enlightenment
            </p>
          </div>
        </div>
      </div>

      {/* -------------------- Block 3 -------------------- */}
      <div className="bg-dark-green px-20 md:px-40 py-20">
        <div className="text-left">
          <h1 className="text-gold text-4xl monomaniac-one mb-5">
            What We Do
          </h1>
          <p className="text-white text-lg lg:text-lg jet-brains-mono font-medium mb-20">
            Bridging communities with compassion and Islamic enlightenment
          </p>
        </div>
      </div>

      {/* -------------------- Block 4 -------------------- */}

      {/* -------------------- Block 5 -------------------- */}
      <div className="flex flex-col items-center bg-gold px-20 md:px-40 py-4">
        <h1 className="text-black text-4xl monomaniac-one mb-5">
          Reach out to Us!
        </h1>
        <div className="text-black flex w-full justify-evenly text-4xl lg:text-5xl tablet:w-3/4 jet-brains-mono font-medium">
          <FontAwesomeIcon icon={faFacebook}/>
          <FontAwesomeIcon icon={faInstagram}/>
          <FontAwesomeIcon icon={faLinkedin}/>
          <FontAwesomeIcon icon={faEnvelope}/>
        </div>
      </div>
    </div>
  )
}

export default Overview