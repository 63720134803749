
const HomePage = (props) => {
  return (
    <div>
      <div className="bg-dark-green h-96 px-40 py-20 text-left h-screen">
        <h1 className="text-white">Home Page</h1>
      </div>
      {/* <div className="bg-white h-96">
        
      </div> */}
    </div>
  )
}

export default HomePage