import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import Moment from 'moment';

const NewsEntry = (props) => {
  return (
    <div className="flex bg-white items-center justify-between">
      <div className="flex flex-col">
        {/* -------------- Header -------------- */}
        <div className="flex flex-row">
          <div className="bg-gold px-8 py-2 text-sm jet-brains-mono"><i>News Release</i></div>
          <div className="px-3 py-2 text-sm jet-brains-mono">{Moment(props.date).format('MMMM d, YYYY')}</div>
        </div>
        <h1 className="px-12 py-5 text-xl jet-brains-mono">{props.title}</h1>
      </div>
      <a href="/news">
        <FontAwesomeIcon icon={faArrowRight}
          className="text-gold border border-gold hover:text-white hover:bg-gold transition-all ease-in-out duration-100"
          style={{
            width: "40px",
            height: "40px",
            padding: "5px",
            marginRight: "20px",
            borderRadius: "50%",
            borderWidth: "5px"
          }}
        />
      </a>
    </div>
  )
}

const News = (props) => {
  return (
    <div>
      <div className="bg-white px-20 md:px-40 py-20 text-left">
        <h1 className="text-gold text-4xl monomaniac-one"><span className="underline underline-offset-8 decoration-black decoration-2">Ne</span>ws</h1>
      </div>
      <div className="bg-dark-green px-20 md:px-40 py-20 text-left flex flex-col gap-8">
        <NewsEntry
          id={0}
          date={new Date()}
          newRelease={false}
          title={"New Local Funding Announced!"}
        />
        <NewsEntry
          id={0}
          date={new Date()}
          newRelease={false}
          title={"New Local Funding Announced!"}
        />
        <NewsEntry
          id={0}
          date={new Date()}
          newRelease={false}
          title={"New Local Funding Announced!"}
        />
      </div>
    </div>
  )
}

export default News