import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-regular-svg-icons"
import { faFacebook, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons"

const SponsorshipsAndCollaboration = (props) => {
  return (
    <div>
      {/* -------------------- Block 1 -------------------- */}
      <div className="bg-dark-green px-20 md:px-40 py-20 text-left">
        <h1 className="text-gold text-4xl monomaniac-one mb-5">Sponsorships & Collaboration</h1>
        <div>
          <ul className="marker:text-gold list-disc border border-white border-l border-r-0 border-y-0 pl-[10.5px]">
            <li className="pl-4 mb-10">
              <div className="inline-flex flex-col w-full align-middle">
                <div className="text-white text-sm jet-brains-mono">
                  We encourage any individuals or organizations to reach out to us about partnership opportunities.
                </div>
              </div>
            </li>

            <li className="pl-4 mb-10">
              <div className="inline-flex flex-col w-full align-middle">
                <div className="text-white text-sm jet-brains-mono">
                  Please feel free to contact us by email at <a href="mailto:admin@alhaqqfoundation.ca" className="text-gold">admin@alhaqqfoundation.ca</a> or through our social media
                  and we will reach out to you as soon as possible to help facilitate the process.
                </div>
              </div>
            </li>
            <li className="pl-4">
              <div className="inline-flex gap-6 text-4xl text-gold align-middle">
                <FontAwesomeIcon icon={faFacebook} />
                <FontAwesomeIcon icon={faInstagram} />
                <FontAwesomeIcon icon={faLinkedin} />
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default SponsorshipsAndCollaboration